import React from "react"
import queryString from 'query-string';
import { getCookieItem, setCookieItem } from "../utils/common"
import { useTheme } from "../context/ThemeContext"
import { planService } from "../services";

class HomePage extends React.Component {
    componentDidMount() {
        const { query, theme, themeToggle } = this.props;
        let affiliateId = getCookieItem("aId") || "";
        let promoCode = "";
        if (query) {
            const queriedTheme = queryString.parse(query);
            promoCode = queriedTheme.promo || ""
            affiliateId = queriedTheme.aId || affiliateId
            if (affiliateId) {
                setCookieItem("aId", affiliateId, 60)
            }
        }
        const updatedTheme = {
            ...theme,
            affiliateId,
            promoCode
        }
        themeToggle.setThemeState(updatedTheme);
        this.getPricingPlans(updatedTheme, themeToggle);
        this.bindAppCues();
    }

    bindAppCues() {
        setTimeout(() => {
            if (typeof window != "undefined" && window.Appcues) {
                window.Appcues.anonymous();
                console.log("Appcues")
            }
        }, 5000);
    }

    getPricingPlans(theme, themeToggle) {
        planService
            .getMainSignUpPricingPageData(theme.promoCode, theme.affiliateId)
            .then(pricingPlanResponse => {
                if (pricingPlanResponse) {
                    themeToggle.setThemeState({
                        ...theme,
                        showLoader: false,
                        pricingPlanResponse
                    })
                }
            })
    }

    render() {
        return <></>
    }
}

const Home = ({ theme, location }) => {
    const themeToggle = useTheme()
    return <HomePage query={location.search} theme={theme} themeToggle={themeToggle} />
}

export default Home
