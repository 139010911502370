import { HttpClient } from "./http-client"
import { apiUrl } from "./api-urls"
import { toCamel } from "../utils/apiResponse"
import { PricingPlanResponseModel, VerifyPromoResponse } from "../components/models"
class PlanService {
  httpClient = new HttpClient()

  async getMainSignUpPricingPageData(promoCode, affiliateSource = "") {
    try {
      let param = new URLSearchParams()
      param.append("promoCode", promoCode)
      if (affiliateSource) {
        param.append("sourceId", affiliateSource)
      }
      const response = await this.httpClient.get(apiUrl.getMainSignUpPricingPageData, param)
      const { Success, Data } = response.data
      if (Success && Data) {
        return new PricingPlanResponseModel(toCamel(Data))
      }
      return null
    } catch (e) {
      console.log(e)
      return null
    }
  }

  async saveAddOnitemsDetailForSignUpFromPricingPlan(
    signUpPricingPlanItemsRequestVM
  ) {
    try {
      const response = await this.httpClient.post(
        apiUrl.saveAddOnitemsDetailForSignUpFromPricingPlan,
        signUpPricingPlanItemsRequestVM
      )
      if (response.data.Data) {
        if (response.data.Data.Success) {
          return toCamel(response.data.Data)
        }
        alert(response.data.Data.ErrorMessage)
      }
      return ""
    } catch (e) {
      return ""
    }
  }

  async verifyPromoCode(
    promoCode,
    subscriptionTerm,
    purchaseAmount,
    affiliateSource = "",
    planId = ""
  ) {
    try {
      let param = new URLSearchParams()
      param.append("promoCode", promoCode)
      param.append("subscriptionTerm", subscriptionTerm)
      param.append("purchaseAmount", purchaseAmount)
      if (affiliateSource) {
        param.append("source", affiliateSource)
      }
      if (planId) {
        param.append("planId", planId)
      }
      const response = await this.httpClient.get(apiUrl.verifyPromoCode, param)
      if (response.data.Data) {
        return new VerifyPromoResponse(toCamel(response.data.Data))
      } else {
        return new VerifyPromoResponse()
      }
    } catch (e) {
      return new VerifyPromoResponse()
    }
  }
}

export default new PlanService()
